import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d74fea42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question" }
const _hoisted_2 = { class: "answer" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.open ? 'faq open' : 'faq'),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleOpen', _ctx.index)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.emailBody.subject), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        innerHTML: _ctx.emailBody.body
      }, null, 8, _hoisted_3)
    ])
  ], 2))
}