
import genesyscloudService from '@/services/genesyscloud-service'
import { defineComponent } from 'vue'
import EmailBodyModal from './EmailBodyModal.vue'
import DisconnectModal from './DisconnectModal.vue'
import TransferModal from './TransferModal.vue'
import Spinner from './Spinner.vue'
import { Item, FilterOption } from 'vue3-easy-data-table'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

type conversation = {
  conversationId? : string,
  conversationStart?: string,
  name?: string,
  externalContactId?: string,
  participantId?: string,
  transferredQueue?: string,
  agent?: string,
  queue?: string,
  from?: string,
  to?: string,
  subject?: string,
  state?: string,
  direction?: string
}

type emailBody = {
    subject: string,
    body: string,
    open: boolean
}

export default defineComponent({
  components: { EmailBodyModal, DisconnectModal, TransferModal, Spinner },
  props: {
    conversations: {
      type: Array,
      required: true
    },
    queuename: {
      type: String
    },
    wrapupCodes: {
      type: Array
    },
    queue: {
      type: Object
    },
    queues: {
      type: Array,
      required: true
    },
    getConversationDetails: {
      type: Function,
      required: true
    },
    selectedQueueWaiting: {
      type: Number
    },
    selectedQueueInteracting: {
      type: Number
    },
    userId: {
      type: String
    }
  },
  // components: { CustomModal },
  data () {
    return {
      rotate: false,
      multipleSelection: false,
      showBody: false,
      showEmailBodyModal: false,
      showTransferModal: false,
      showDisconnectModal: false,
      isItemLoading: false,
      isMetricChanged: false,
      showTypeFilter: false,
      queuenameChanged: false,
      conversationId: '',
      participantId: '',
      searchValue: '',
      searchField: '',
      from: '',
      typeCriteria: 'all',
      itemsSelected: [],
      headers: [
        { text: 'De', value: 'from', sortable: true },
        { text: 'À', value: 'to', sortable: true },
        { text: 'Objet', value: 'subject', sortable: true },
        { text: 'Date', value: 'conversationStart', sortable: true },
        { text: 'Compétence', value: 'skills', sortable: true },
        { text: 'Type', value: 'state', sortable: true },
        { text: 'Direction', value: 'direction', sortable: true },
        { text: 'Agent', value: 'agent', sortable: true },
        { text: 'Action', value: 'action' }
      ],
      emailBodies: [] as Array<emailBody>,
      items: this.conversations
    }
  },
  watch: {
    conversations () {
      this.items = this.conversations
    },
    selectedQueueWaiting (newval, oldval) {
      if (!this.queuenameChanged) {
        this.isMetricChanged = oldval !== undefined
      }
    },
    selectedQueueInteracting (newval, oldval) {
      if (!this.queuenameChanged) {
        this.isMetricChanged = oldval !== undefined
      }
    },
    queuename (newval, oldval) {
      this.queuenameChanged = oldval !== newval
    }
  },
  inject: ['notyf'],
  methods: {
    bodyRowClassNameFunction (item: Item) {
      if (item.agent !== '') {
        if (item.direction === 'Sortant') {
          return 'hide-viewbody'
        } else {
          return 'pass-row'
        }
      }
      return ''
    },
    reload () {
      if (this.queue) {
        console.log('queue: ', this.queue)
        this.rotate = true
        setTimeout(() => {
          this.getConversationDetails(this.queue)
          this.rotate = false
          this.isMetricChanged = false
        }, 1000)
      }
    },
    toggleReloadButoon () {
      this.isMetricChanged = true
    },
    resetQueueNameChange () {
      setTimeout(() => {
        this.queuenameChanged = false
      }, 5000)
    },
    hideReload () {
      this.isMetricChanged = false
    },
    resetItemSelected () {
      this.itemsSelected = []
    },
    closeEmailBodyModal () {
      this.showEmailBodyModal = false
      this.conversationId = ''
      // this.emailBodies = []
      // this.from = ''
    },
    closeDisconnectModal () {
      this.showDisconnectModal = false;
      (this.$refs.childComponent as { resetData: () => void }).resetData()
    },
    closeTransferModal () {
      this.showTransferModal = false;
      (this.$refs.childTRComponent as { resetData: () => void }).resetData()
    },
    toggleOpen: function (index: number) {
      this.emailBodies = this.emailBodies.map((emailBody, i) => {
        if (index === i) {
          emailBody.open = !emailBody.open
        } else {
          emailBody.open = false
        }
        return emailBody
      })
    },
    async claim (item: Record<string, unknown>) {
      const notyf = this.notyf as Notyf
      this.conversationId = ''
      this.participantId = ''
      const selectedItem = item as conversation
      if (selectedItem && selectedItem.conversationId && selectedItem.participantId && this.userId) {
        this.conversationId = selectedItem.conversationId
        this.participantId = selectedItem.participantId
        this.isItemLoading = true
        try {
          await genesyscloudService.replaceParticipant(this.conversationId, this.participantId, 'user', this.userId)
          notyf.success('L\'interaction vous a bien été assignée')
        } catch (error) {
          notyf.error('Une erreur s\'est produite')
        }
        this.isItemLoading = false
      }
    },
    transferAll () {
      this.multipleSelection = true
      this.showTransferModal = true
    },
    transfer (item: Item) {
      this.multipleSelection = false
      this.conversationId = ''
      this.participantId = ''
      const selectedItem = item as conversation
      if (selectedItem && selectedItem.conversationId && selectedItem.participantId) {
        this.conversationId = selectedItem.conversationId
        this.participantId = selectedItem.participantId
        this.showTransferModal = true
      }
    },
    viewInteraction (item: Record<string, unknown>) {
      const url = `https://apps.mypurecloud.de/directory/#/engage/admin/interactions/${item.conversationId}`
      window.open(url)
    },
    async viewBody (item: Record<string, unknown>) {
      const selectedItem = item as conversation
      if (selectedItem && selectedItem.conversationId) {
        this.conversationId = selectedItem.conversationId
        this.isItemLoading = true
        // (this.$refs.child as child).test(selectedItem.conversationId)

        const response = await genesyscloudService.getEmailMessages(this.conversationId)
        const messageIds = response?.map(message => message.id)
        if (messageIds) {
          const promises = messageIds.map(async id => {
            if (id) {
              const data = await genesyscloudService.getBody(this.conversationId, id)
              return data
            }
          })
          const results = await Promise.all(promises)
          const temp = results.map((res, index) => {
            const attachments = res?.attachments || []
            if (res?.htmlBody) {
              for (const attachment of attachments) {
                if (attachment.attachmentId && attachment.contentUri) {
                  res.htmlBody = res?.htmlBody.replace('cid:' + attachment.attachmentId.replace('@', '&#64;'), attachment.contentUri)
                }
              }
            }
            return {
              subject: `${res?.subject}${results.length > 1 ? ` - ${results.length - index}/${results.length}` : ''}`,
              body: res?.htmlBody || res?.textBody || '',
              open: results.length === 1
            }
          })
          this.emailBodies = temp
          this.from = selectedItem.from || ''
          this.isItemLoading = false
          this.showEmailBodyModal = true
        }
      } else {
      }
    },
    disconnectAll () {
      this.multipleSelection = true
      this.showDisconnectModal = true
    },
    disconnect (item: Item) {
      this.multipleSelection = false
      this.conversationId = ''
      this.participantId = ''
      const selectedItem = item as conversation
      if (selectedItem && selectedItem.conversationId && selectedItem.participantId) {
        this.conversationId = selectedItem.conversationId
        this.participantId = selectedItem.participantId
        this.showDisconnectModal = true
      }
    }
  },
  computed: {
    showheader () {
      return false
    },
    filterOptions () {
      const filterOptionsArray: FilterOption[] = []
      if (this.typeCriteria !== 'all') {
        filterOptionsArray.push({
          field: 'state',
          comparison: '=',
          criteria: this.typeCriteria
        })
      }
      // this.showTypeFilter = false
      return filterOptionsArray
    },
    itemsSelectedFiltered () {
      return this.itemsSelected.filter((item: conversation) => item.agent === '')
    },
    anchorReloadClass () {
      const classes = ['btn', 'btn--reload']
      if (this.isMetricChanged) {
        classes.push('animated-link')
      }
      if (this.itemsSelectedFiltered.length > 1) {
        classes.push('mr-sm')
      }
      return classes.join(' ')
    }
  },
  // beforeCreate () {
  //   const notyf = this.$options.inject && this.$options.inject.notyf
  //   this.$notyf = notyf || new Notyf()
  // },
  created () {
    if (this.conversations.length !== 0) {
      console.log(this.conversations)
    }
  }
})

