
import { defineComponent } from 'vue'
import platformClient from 'purecloud-platform-client-v2'
import genesysCloudService from './../services/genesyscloud-service'
import DataTable from '@/components/DataTable.vue'
import Spinner from '@/components/Spinner.vue'
// import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
// import { ClickRowArgument, Item } from 'vue3-easy-data-table'

interface Metric {
  metric: string;
  stats: {
    count: number;
  };
}

interface Data {
  interval: string;
  metrics: Metric[];
}

interface eventBody {
  group: {
    queueId: string;
    mediaType: string;
  };
  data: Data[];
}

type conversation = {
  conversationId? : string,
  conversationStart?: string,
  name?: string,
  externalContactId?: string,
  participantId?: string,
  transferredQueue?: string,
  isTransferOut?: boolean,
  agent?: string,
  queue?: string,
  from?: string,
  subject?: string,
  state?: string
}

export default defineComponent({
  name: 'Monitor',
  components: { DataTable, Spinner },
  props: ['userId'],
  data () {
    return {
      isItemLoading: false,
      queuename: '',
      queueWaiting: 0,
      currentQueueWaiting: 0,
      currentQueueInteracting: 0,
      currentIndex: -1,
      queueIds: [] as string[],
      queues: [] as platformClient.Models.Queue[],
      queue: {} as platformClient.Models.Queue,
      data: [] as platformClient.Models.QueueObservationDataContainer[],
      conversations: [] as Array<conversation>,
      wrapupcodes: [] as { name: string, id: string | undefined}[],
      headers: [
        { text: 'Nom', value: 'name', sortable: true },
        { text: 'En attente', value: 'oWaiting', sortable: true },
        { text: 'Interactions', value: 'oInteracting', sortable: true }
      ],
      items: [] as platformClient.Models.Queue[]
    }
  },
  methods: {
    async fetchData () {
      try {
        const queues = await genesysCloudService.getQueues('')
        if (queues) {
          this.queueIds = queues.map(queue => queue.id ?? '')
          const predicateQueues = this.queueIds.map(item => ({
            dimension: 'queueId',
            value: item
          })) as platformClient.Models.QueueObservationQueryPredicate
          const res = await genesysCloudService.postQueueObservation(predicateQueues)
          if (res) {
            this.data = res
          }
          this.queues = queues
          queues.forEach(queue => {
            queue.oWaiting = this.getMetric(queue, 'oWaiting')
          })
          queues.forEach(queue => {
            queue.oInteracting = this.getMetric(queue, 'oInteracting')
          })
          this.items = this.queues
        }
        if (!this.queueIds.length) console.log('No queue Ids were provided')
        else await genesysCloudService.subscribeToQueuesObservations(this.queueIds, [this.onQueueEvent])
      } catch (err) {
        console.error(err)
      }
    },
    onQueueEvent (message: MessageEvent): void {
      const data = JSON.parse(message.data)
      const topicName = data.topicName
      const eventBody = data.eventBody as eventBody
      // Update agent view
      const topicRegex = /(v2\.analytics.queues\.)(.*)\.(.*)/g
      const match = topicRegex.exec(topicName)
      if (!match) return
      const queueId = match[2]
      const index = this.queues.findIndex(queue => queue.id === queueId)
      if (eventBody.group.mediaType === 'email') {
        eventBody.data[0].metrics.forEach(el => {
          if (el.metric === 'oWaiting') {
            this.queues[index].oWaiting = el.stats.count
            this.queue.oWaiting = el.stats.count
            if (el.stats.count === this.currentQueueWaiting) {
            } else {
              if (this.queue.id === queueId) {
                (this.$refs.childComponent as { toggleReloadButoon: () => void }).toggleReloadButoon()
              }
            }
          } else if (el.metric === 'oInteracting') {
            this.queues[index].oInteracting = el.stats.count
            this.queue.oInteracting = el.stats.count
            if (el.stats.count === this.currentQueueInteracting) {
            } else {
              if (this.queue.id === queueId) {
                (this.$refs.childComponent as { toggleReloadButoon: () => void }).toggleReloadButoon()
              }
            }
          }
        })
      }

      // eventBody.data[0].metrics.forEach((element) => {
      //   if (element.metric === 'oInteracting') {
      //     this.queues[index].oInteracting = element.stats.count
      //   } else if (element.metric === 'oWaiting') {
      //     this.queues[index].oWaiting = element.stats.count
      //   }
      //   if (this.queue.id === queueId) {
      //     (this.$refs.childComponent as { toggleReloadButoon: () => void }).toggleReloadButoon()
      //   }
      // })
    },
    getMetric (queue: platformClient.Models.Queue, metricName: string) {
      const queueId = queue.id
      const el = this.data.findIndex(d => d.group && d.group.queueId === queueId)
      const data = this.data[el]?.data
      if (data) {
        const metric = data.find(metric => metric.metric === metricName)
        // this.currentW = metric?.stats?.count || 99
        return metric?.stats?.count
      }
    },
    async getConversationDetails (queue: platformClient.Models.Queue) {
      if (queue.oWaiting === 0 && queue.oInteracting === 0 && Object.keys(this.queue).length === 0) return
      // if (item.oWaiting === 0 && item.oInteracting === 0) return
      // const queue = item as platformClient.Models.Queue
      (this.$refs.childComponent as { hideReload: () => void }).hideReload();
      // this.isItemLoading = true;
      (this.$refs.childComponent as { resetItemSelected: () => void }).resetItemSelected()
      // this.conversations = []
      if (queue.id !== undefined) {
        // if (queue.oWaiting === 0 && queue.oInteracting === 0) return
        this.isItemLoading = true
        this.currentQueueWaiting = queue.oWaiting || 0
        this.currentQueueInteracting = queue.oInteracting || 0
        this.queue = queue
        this.queuename = queue.name || ''
        // this.queueWaiting = this.selectedQueueWaiting(queue)
        try {
          const res = await genesysCloudService.postAnalyticsConversationsdetails(queue.id)
          if (res && res.conversations) {
            const { conversations } = res
            let skills = [] as string[]
            this.conversations = await Promise.all(
              conversations.map(async (item) => {
                const participants = item.participants

                if (!participants) {
                  return {
                    conversationId: item.conversationId
                  }
                }

                let agent = ''
                let agentParticipantId = ''
                let state = ''
                let direction = ''
                const lastAgent = participants.reduce((last: platformClient.Models.AnalyticsParticipantWithoutAttributes, current: platformClient.Models.AnalyticsParticipantWithoutAttributes) => {
                  return current.purpose === 'agent' ? current : last
                }, {})
                // console.log('lastAgennt', lastAgent)
                // New code
                // const agentSessionsArr = participants
                //   .filter(agent => agent.purpose === 'agent')
                //   .map(agent => agent.sessions?.reduce((last, current) => current.metrics || last.metrics ? current : last, {}))

                // const sessionWithSegment = agentSessionsArr.find(session => session?.segments?.some(segment => segment.segmentType === 'interact' && segment.segmentEnd === undefined))
                // const sessionIdResult = sessionWithSegment?.sessionId || ''
                // const participantResult = participants.filter(agent => agent.purpose === 'agent').find(session => session.sessions && session.sessions.some(el => el.sessionId === sessionIdResult))
                // console.log(participantResult?.participantName)
                // console.log('lastAgent ', lastAgent)
                if (lastAgent.sessions?.length === 1 && lastAgent.sessions[0].direction === 'outbound' && !participants.some(participant => participant.purpose === 'acd')) {
                  const startTime = lastAgent.sessions[0].metrics?.find(metric => metric && metric.name === 'nOutbound')
                  direction = 'Sortant'
                  const user = await genesysCloudService.getUserById(lastAgent.userId || '')
                  const isTransferOut = participants.some(participant => participant.purpose === 'acd')
                  return {
                    conversationId: item.conversationId,
                    from: lastAgent.sessions[0].addressFrom,
                    to: lastAgent.sessions[0].addressTo,
                    agent: user.name,
                    direction,
                    participantId: lastAgent.participantId,
                    transferredQueue: '',
                    isTransferOut,
                    externalContactId: '',
                    subject: '',
                    state: 'Interaction',
                    conversationStart: new Date(
                      startTime && startTime.emitDate ? startTime.emitDate : ''
                    )
                      .toLocaleString([], {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                  }
                }
                if (Object.keys(lastAgent).length !== 0) {
                  const agentSessionsArr = participants.flatMap(agent =>
                    agent.purpose === 'agent'
                      ? agent.sessions?.reduce((last, current) => current.metrics || last.metrics ? current : last, {})
                      : []
                  )
                  const sessionWithSegment = agentSessionsArr.find(session => session?.segments?.some(segment => segment.segmentType === 'interact' && segment.segmentEnd === undefined))
                  const sessionWithSegmentHold = agentSessionsArr.find(session => session?.segments?.some(segment => segment.segmentType === 'hold' && segment.segmentEnd === undefined))
                  const sessionEnded = agentSessionsArr.find(session => session?.segments?.some(segment => segment.segmentType === 'interact' && segment.segmentEnd && segment.disconnectType === 'client'))
                  if (sessionEnded) {
                    return {
                      conversationId: item.conversationId,
                      isTransferOut: true
                    }
                  }
                  if (sessionWithSegmentHold) {
                    const sessionIdResultHold = sessionWithSegmentHold?.sessionId || ''
                    const participantResultHold = participants.filter(agent => agent.purpose === 'agent').find(session => session.sessions && session.sessions.some(el => el.sessionId === sessionIdResultHold))

                    state = 'Interaction'
                    agent = state === 'Interaction' && participantResultHold?.participantName ? participantResultHold.participantName : ''
                    agentParticipantId = participantResultHold?.participantId || ''
                  } else {
                    const sessionIdResult = sessionWithSegment?.sessionId || ''
                    const participantResult = participants.filter(agent => agent.purpose === 'agent').find(session => session.sessions && session.sessions.some(el => el.sessionId === sessionIdResult))

                    state = sessionWithSegment ? 'Interaction' : 'En attente'
                    agent = state === 'Interaction' && participantResult?.participantName ? participantResult.participantName : ''
                    agentParticipantId = participantResult?.participantId || ''
                  }
                  // state = sessionWithSegment ? 'Interaction' : 'En attente'
                  // agent = state === 'Interaction' && participantResult?.participantName ? participantResult.participantName : ''
                  // const lastSession = lastAgent.sessions?.reduce((last: platformClient.Models.AnalyticsSession, current: platformClient.Models.AnalyticsSession) => {
                  //   return current.metrics || last.metrics ? current : last
                  // }, {})
                  // if (lastSession?.segments) {
                  //   const tInteractSegment = lastSession.segments.find(segment => segment.segmentType === 'interact')
                  //   state = tInteractSegment?.segmentStart && !tInteractSegment?.segmentEnd ? 'Interaction' : 'En attente'
                  //   agent = state === 'Interaction' && lastAgent.participantName ? lastAgent.participantName : ''
                  // } else {
                  //   state = 'En attente'
                  // }
                  // if (lastSession?.metrics) {
                  //   const tAnswered = lastSession.metrics.find(metric => metric && metric.name === 'tAnswered')
                  //   const tTalkComplete = lastSession.metrics.find(metric => metric && metric.name === 'tTalkComplete')
                  //   state = tAnswered && !tTalkComplete ? 'Interaction' : 'En attente'
                  //   agent = state === 'Interaction' && lastAgent.participantName ? lastAgent.participantName : ''
                  // } else {
                  //   state = 'En attente'
                  // }
                } else {
                  state = 'En attente'
                }
                const externalParticipant = participants.find(p => p.purpose === 'external') || participants.find(p => p.purpose === 'customer')

                const acdArray = participants.filter(participant => participant.purpose === 'acd')
                const acdParticipantNames = acdArray.map(participant => participant.participantName)
                // const [first, last] = [acdParticipantNames[0], acdParticipantNames[acdParticipantNames.length - 1]]
                const last = acdParticipantNames[acdParticipantNames.length - 1]

                const acdParticipant = participants.reverse().find((p) => p.purpose === 'acd')
                // console.log('ACD', acdParticipant)

                let addressFrom = ''
                let addressTo = ''
                let participantName = ''
                let participantId = ''
                let transferredQueue = ''
                let isTransferOut = false
                let externalContactId = ''
                let queueName = ''
                let subject = ''
                if (externalParticipant) {
                  participantName = externalParticipant.participantName || ''
                  externalContactId = externalParticipant.externalContactId || ''
                  if (externalParticipant.sessions && externalParticipant.sessions[0]) {
                    const {
                      addressFrom: externalAddressFrom,
                      segments
                    } = externalParticipant.sessions[0]
                    addressFrom = externalAddressFrom || ''
                    if (segments && segments[0]) {
                      subject = segments[0].subject || ''
                    }
                  }
                }

                if (acdParticipant) {
                  queueName = acdParticipant.participantName || ''
                  participantId = acdParticipant.participantId || ''
                  transferredQueue = (acdParticipant && acdParticipant.participantName && acdParticipant.participantName !== this.queuename) ? acdParticipant.participantName : ''
                  if (
                    acdParticipant.sessions &&
                    acdParticipant.sessions[0].segments
                  ) {
                    addressTo = acdParticipant.sessions[0].addressTo || ''
                    skills = acdParticipant.sessions[0].segments[0].requestedRoutingSkillIds || []
                  }
                }
                isTransferOut = last !== this.queuename
                // if (agent !== '') {
                //   state = 'Waiting'
                // } else {
                //   state = 'Interacting'
                // }
                const conversationDetails = {
                  conversationId: item.conversationId,
                  queue: queueName,
                  participantId: agent !== '' ? agentParticipantId : participantId,
                  transferredQueue,
                  isTransferOut,
                  agent,
                  state,
                  direction: 'Entrant',
                  // from: `(${participantName}) ${addressFrom}`,
                  from: addressFrom,
                  to: addressTo,
                  name: participantName,
                  externalContactId,
                  conversationStart: new Date(
                    item.conversationStart ? item.conversationStart : ''
                  )
                    .toLocaleString([], {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    }),
                  subject
                }

                if (skills.length !== 0) {
                  const skillNames = await this.processSkillsAndGetSkill(skills)
                  return { ...conversationDetails, skills: skillNames }
                } else {
                  return conversationDetails
                }
              })
            )
            this.conversations = this.conversations.filter(conversation => !conversation.isTransferOut)
            this.isItemLoading = false
            // (this.$refs.childComponent as { resetQueueNameChange: () => void }).resetQueueNameChange()
            const wraps = await genesysCloudService.getQueueWrapupCodes(queue.id)
            if (wraps) {
              this.wrapupcodes = wraps.map(wrap => ({ name: wrap.name, id: wrap.id }))
            }
          } else {
            this.conversations = []
            this.queue = {}
            this.wrapupcodes = []
            console.log('conv is undefined')
            this.isItemLoading = false
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        console.log('Queue Id is undefined !!')
      }
    },
    async processSkillsAndGetSkill (skillids: string[]) {
      const skillnames = await Promise.all(
        skillids.map(async (skill) => {
          const sk = await genesysCloudService.getSkill(skill)
          return sk.name
        })
      )
      return skillnames
    }
  },
  created () {
    this.fetchData()
  }
})
