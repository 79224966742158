
import platformClient from 'purecloud-platform-client-v2'
import genesysCloud from './services/genesyscloud-service'
import { defineComponent } from 'vue'
import Navbar from './components/Navbar.vue'
import config from './config/config'

export default defineComponent({
  name: 'Finder',
  components: {
    Navbar
  },
  data () {
    return {
      shouldShowNavbar: true,
      isSupervisor: false,
      agentMember: {} as platformClient.Models.User,
      userId: '',
      profile: '',
      lang: '',
      permissions: [] as string[]
    }
  },
  methods: {
    async getRoles (userId: string) {
      try {
        const res = await genesysCloud.getUserRoles(userId)
        if (res && res.permissions) {
          this.permissions = res.permissions
          this.isSupervisor = this.permissions.some((permission) => permission.includes('ui:supervisor'))
          this.profile = this.isSupervisor ? 'supervisor' : 'agent'
        }
      } catch (error) {
        console.log(error)
      }
    },
    async login () {
      let redirectUri = config.redirectUri
      if (window.location.href.includes('/myinteractions')) {
        redirectUri += 'myinteractions'
      }
      try {
        await genesysCloud.loginImplicitGrant()
        history.pushState({}, '', redirectUri)
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUserData () {
      try {
        const data = await genesysCloud.getUsersMe()
        this.agentMember = data
        this.userId = this.agentMember.id !== undefined ? this.agentMember.id : ''
        await this.getRoles(this.userId)
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    // profile (): string {
    //   return this.isSupervisor ? 'supervisor' : 'agent'
    // }
  },
  async created (): Promise<void> {
    this.shouldShowNavbar = !window.location.href.includes('/myinteractions')
    console.log('App created')
    await this.login()
  },
  async mounted (): Promise<void> {
    console.log('App mounted')
    await this.fetchUserData()
    // try {
    //   const data = await genesysCloud.getUsersMe()
    //   this.agentMember = data
    //   this.userId = this.agentMember.id !== undefined ? this.agentMember.id : ''
    //   await this.getRoles(this.userId)
    // } catch (error) {
    //   console.log(error)
    // }
  }
})
