import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.shouldShowNavbar)
      ? (_openBlock(), _createBlock(_component_Navbar, {
          key: 0,
          isSupervisor: _ctx.isSupervisor
        }, null, 8, ["isSupervisor"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, {
      userId: _ctx.userId,
      isSupervisor: _ctx.isSupervisor,
      profile: _ctx.profile
    }, null, 8, ["userId", "isSupervisor", "profile"])
  ]))
}