
import { defineComponent } from 'vue'
import platformClient from 'purecloud-platform-client-v2'
import genesysCloudService from '@/services/genesyscloud-service'
import Tag from '@/components/Tag.vue'
// import Spinner from './../components/Spinner.vue'
import ReplyModal from './../components/ReplyModal.vue'
import Dropdown from './../components/Dropdown.vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'
import ClientApp from 'purecloud-client-app-sdk'

const myClientApp = new ClientApp({
  pcEnvironment: 'mypurecloud.de'
})

type gbQueue = {
  name: string,
  id: string
}

type gbUser = {
  name: string,
  id: string
}

type gbDivision = {
  name: string,
  id: string
}
export default defineComponent({
  name: 'Email',
  props: {
    isSupervisor: {
      type: Boolean,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    profile: {
      type: String,
      required: true
    }
  },
  components: { DatePicker, Tag, Dropdown, ReplyModal },
  data () {
    return {
      showReply: false,
      showFilter: false,
      showFilters: true,
      showQueues: false,
      showUsers: false,
      showDivisions: false,
      queuesLoading: false,
      usersLoading: false,
      isItemLoading: false,
      searchLoading: false,
      searchRecording: false,
      divisionLoading: false,
      fromCheckbox: false,
      toCheckbox: false,
      wrongFromEmail: false,
      wrongToEmail: false,
      searchStr: '',
      to: '',
      emailTo: '',
      from: '',
      ani: '',
      dnis: '',
      searchQueue: '',
      searchUser: '',
      searchDivision: '',
      mediaType: '',
      tagName: '',
      itemSelected: '',
      queueId: '',
      htmlBody: '',
      conversationId: '',
      externalContactId: '',
      queueName: '',
      subject: '',
      sortBy: 'desc',
      language: 'fr-FR',
      highlightClass: 'highlight',
      tabSelected: 1,
      mtSelected: 5,
      pageNumber: 1,
      pageCount: 1,
      startValue: 0,
      endValue: 0,
      timeout: 0,
      range: [],
      tagNameObj: ['Queue', 'User', 'Division', 'From', 'To', 'ANI', 'DNIS', 'Date'],
      presets: ['Today', 'Yesterday', 'This week', 'Last week', 'Previous 7 days', 'This month', 'Last month', 'Previous 30 days'],
      tags: [] as string[],
      selectedQueues: [] as string[],
      selectedUsers: [] as string[],
      selectedDivisions: [] as string[],
      selectedFroms: [] as string[],
      selectedTos: [] as string[],
      globalQueues: [] as Array<gbQueue>,
      globalUsers: [] as Array<gbUser>,
      globalDivisions: [] as Array<gbDivision>,
      queues: [] as platformClient.Models.Queue[],
      users: [] as platformClient.Models.User[],
      divisions: [] as platformClient.Models.AuthzDivision[],
      interactions: [] as platformClient.Models.ArrayNode[],
      interactionsClone: [] as platformClient.Models.ArrayNode[],
      recordings: [] as platformClient.Models.Recording[],
      modelConfig: {
        type: 'string',
        mask: 'iso',
        timeAdjust: '00:00:00'
      },
      listElm: {} as HTMLElement,
      searchInput: {} as HTMLElement
    }
  },
  methods: {
    onEscape () {
      this.showQueues = false
    },
    reload () {
      this.searchStr = ''
      this.itemSelected = ''
      this.recordings = []
      this.sortBy = 'desc'
      this.toCheckbox = false
      this.fromCheckbox = false
      this.selectedUsers = this.profile === 'agent' ? [this.userId] : [];
      (this.$refs.DropdownChild as { resetDropdown: () => void }).resetDropdown()
      this.getInteractions()
    },
    toggleFilters () {
      this.showFilter = true
    },
    removeDuplicate (interactions : platformClient.Models.JsonSearchResponse) {
      return interactions.results.reduce((finalArray: platformClient.Models.ArrayNode[], current: platformClient.Models.ArrayNode) => {
        const obj = finalArray.find((item) => item.conversationId === current.conversationId)
        if (obj) {
          return finalArray
        }
        return finalArray.concat([current])
      }, [])
    },
    onFromInput (event: Event) {
      const target = event.target as HTMLInputElement
      const input = target.value
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.wrongFromEmail = input.length > 0 && !regex.test(input)
      // if (input.length === 0) {
      //   this.wrongFromEmail = false
      // } else {
      //   if (regex.test(input)) {
      //     this.wrongFromEmail = false
      //   } else {
      //     this.wrongFromEmail = true
      //   }
      // }
      // if (event.target) {
      //   const input = (event.target as HTMLInputElement).value
      //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      //   if (regex.test(input)) {
      //     this.from = input
      //   } else {
      //     this.from = this.from.slice(0, -1)
      //   }
      // }
    },
    onToInput (event: Event) {
      const target = event.target as HTMLInputElement
      const input = target.value
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.wrongToEmail = input.length > 0 && !regex.test(input)
    },
    async getInteractions () {
      this.interactions = []
      this.interactionsClone = []
      this.searchLoading = true
      this.searchInput.blur()
      // let from = {}
      await genesysCloudService.search(this.pageNumber = 1, this.mediaType, this.startValue, this.endValue, this.language, this.searchStr, this.selectedQueues, this.selectedUsers, this.selectedDivisions, this.selectedFroms, this.selectedTos, this.sortBy)
        .then(interactions => {
          if (interactions) {
            if (interactions.total === 0) {
              this.recordings = []
              return
            }
            // this.interactions = interactions.results
            // this.interactionsClone = [...interactions.results]
            // this.pageCount = interactions.pageCount
            this.interactions = this.removeDuplicate(interactions)
            this.interactionsClone = [...this.interactions]
            this.pageCount = interactions.pageCount
          }
        })
      this.searchLoading = false
    },
    async loadMoreInteractions () {
      await genesysCloudService.search(this.pageNumber, this.mediaType, this.startValue, this.endValue, this.language, this.searchStr, this.selectedQueues, this.selectedUsers, this.selectedDivisions, this.selectedFroms, this.selectedTos, this.sortBy)
        .then(interactions => {
          if (interactions) {
            console.log('loading ...: ', interactions)
            this.interactions.push(...interactions.results)
            this.interactionsClone.push(...interactions.results)
          }
        })
    },
    async openInteraction (interaction: platformClient.Models.ArrayNode) {
      // this.isItemLoading = true
      this.recordings = []
      this.searchRecording = true
      const direction = (interaction.participants && interaction.participants[0]) ? interaction.participants[0].initialDirection : ''
      // console.log(direction)
      this.queueId = (interaction.participants && interaction.participants[0].queueId) ? interaction.participants[0].queueId : ''
      this.itemSelected = interaction.transcriptId ? interaction.transcriptId : ''
      this.htmlBody = ''
      // this.isItemLoading = true
      if (interaction.conversationId) {
        this.conversationId = interaction.conversationId
        await genesysCloudService.getInteraction(interaction.conversationId)
          .then((recordings) => {
            // this.isItemLoading = false
            this.searchRecording = false
            this.recordings = recordings.reverse()
            this.extractTo(interaction, direction || '', this.recordings)
            this.getExternalContactId()
            this.extractHtmlBody()
            this.extractSubject()
          })
          .then(() => this.getQueueById())
      }
      // this.searchRecording = false
    },
    getExternalContactId () {
      this.externalContactId = this.recordings[0]?.externalContacts?.[0]?.id ?? ''
    },
    async getQueueById () {
      const queue = await genesysCloudService.getQueue(this.queueId)
      this.queueName = queue?.name ?? ''
    },
    extractSubject () {
      this.subject = this.recordings[0]?.emailTranscript?.[0]?.subject ?? ''
    },
    extractTo (interaction: platformClient.Models.ArrayNode, direction: string, recordings: platformClient.Models.Recording[]) {
      if (recordings.length === 0) return ''

      const lastRecording = recordings[recordings.length - 1]

      if (recordings.length === 1) {
        this.emailTo = direction === 'inbound' ? interaction.from || '' : interaction.to ? Object.values(interaction.to).join('; ') : ''
      } else if (lastRecording && lastRecording.emailTranscript) {
        this.emailTo = direction === 'inbound' ? lastRecording.emailTranscript[0].from?.email || '' : lastRecording.emailTranscript[0].to?.map(to => to.email).join('; ') ?? ''
      }
    },
    extractHtmlBody () {
      this.htmlBody = ''
      const results = this.recordings.map(record => record.emailTranscript)

      const htmlArr = []

      for (const recording of results) {
        if (recording) {
          const el = recording[0]
          const { from, to, cc, time, subject, htmlBody, textBody } = el
          if (to && time) {
            const ccString = cc && cc.length > 0 ? `CC: ${cc.map(cc => cc.email).join('; ')} <br/>` : ''
            const attachments = el.attachments || []

            htmlArr.push(`
            <br/> <br/> <div style="border-bottom: 1px solid #e6eaee"></div> <br/> <br/>
            De: ${from?.email} <br/>
            À: ${to.map(to => to.email).join('; ')} <br/>
            ${ccString}
            Date: ${this.formatDate(time)} <br/>
            Objet: ${subject} <br/> <br/>
            ${htmlBody || textBody}
          `)

            for (const attachment of attachments) {
              if (attachment.attachmentId && attachment.contentPath) {
                htmlArr[htmlArr.length - 1] = htmlArr[htmlArr.length - 1].replace('cid:' + attachment.attachmentId.replace('@', '&#64;'), attachment.contentPath)
              }
            }
          }
        }
      }
      this.htmlBody = htmlArr.toString()
      // const strToFind = this.htmlBody.indexOf(':collapse">')
      // const updateTablehtml = this.htmlBody.slice(0, strToFind) + ' style="border: none;"' + this.htmlBody.slice(strToFind + 10)
      // console.log(updateTablehtml)
      // this.htmlBody = updateTablehtml
      // console.log(this.htmlBody)
      // console.log(htmlArr)

      // results.map(el => {
      //   if (el && el[0].htmlBody && el[0].to && el[0].time) {
      //     this.htmlBody += `
      //       <br/> <br/> <div style="border-bottom: 1px solid #e6eaee"></div> <br/> <br/>
      //       De: ${el[0].from?.email} <br/>
      //       À: ${el[0].to.map(to => to.email).join('; ')} <br/>
      //       ${el[0].cc && el[0].cc.length > 0 ? `CC: ${el[0].cc.map(cc => cc.email).join('; ')} <br/>` : ''}
      //       Date: ${this.formatDate(el[0].time)} <br/>
      //       Objet: ${el[0].subject} <br/> <br/>
      //       ${el[0].htmlBody}`
      //   }
      // })
    },
    sortByFrom () {
      let copyFrom = [] as platformClient.Models.ArrayNode[]
      copyFrom = [...this.interactions]
      if (!this.fromCheckbox) {
        const sortedByFrom = copyFrom.sort((a, b) => (a.from > b.from ? 1 : -1))
        this.interactions = sortedByFrom
      } else {
        this.interactions = this.interactionsClone
      }
    },
    sortByTo () {
      let copyTo = [] as platformClient.Models.ArrayNode[]
      copyTo = [...this.interactions]
      if (!this.toCheckbox) {
        const sortedByTo = copyTo.sort((a, b) => (a.to[0] > b.to[0] ? 1 : -1))
        this.interactions = sortedByTo
      } else {
        this.interactions = this.interactionsClone
      }
    },
    fromInteraction (interaction: platformClient.Models.ArrayNode) {
      return interaction.from ?? ''
    },
    formatDate (val: number | string): string {
      return new Date(val).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    },
    toInteraction (interaction: platformClient.Models.ArrayNode) {
      // return interaction.to.map(to => to).join('; ') ?? ''
      return (interaction.to && interaction.to.map(to => to).join('; ')) ?? ''
    },
    getContentType (attachment: platformClient.Models.EmailAttachment) {
      const contentTypes: {[key: string]: string} = {
        'image/jpeg': 'image',
        'image/png': 'image',
        'application/pdf': 'file-pdf',
        'application/word': 'file-word',
        'application/excel': 'file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-word'
      }
      const contentType = attachment?.contentType
      if (contentType && contentType in contentTypes) {
        return contentTypes[contentType]
      } else {
        return 'file-text'
      }
    },
    formatLength (attachment: platformClient.Models.EmailAttachment) {
      const contentLength = attachment.contentLength
      return contentLength ? `${(contentLength / 1000).toFixed(2)} Kb` : ''
    },
    debounce (obj: string) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        if (obj === 'queue') {
          this.getQueues()
        } else if (obj === 'user') {
          this.getUsers()
        } else if (obj === 'division') {
          this.getDivisions()
        } else {}
      }, 1000)
    },
    async getQueues () {
      if (this.searchQueue.length > 0) {
        this.queuesLoading = true
        const queues = await genesysCloudService.getQueues(`*${this.searchQueue}*`)
        const filteredQueues = queues?.filter(queue => !this.selectedQueues.includes(queue.id || ''))
        this.queues = filteredQueues ?? []
        this.queuesLoading = false
        this.showQueues = true
      } else {
        this.showQueues = false
        this.queues = []
      }
    },
    async getUsers () {
      if (this.searchUser.length > 0) {
        this.usersLoading = true
        const users = await genesysCloudService.searchUsers(this.searchUser)
        const filteredUsers = users?.filter(user => !this.selectedUsers.includes(user.id || ''))
        this.users = filteredUsers ?? []
        this.usersLoading = false
        this.showUsers = true
      } else {
        this.showUsers = false
        this.users = []
      }
    },
    async getDivisions () {
      if (this.searchDivision.length > 0) {
        this.divisionLoading = true
        const divisions = await genesysCloudService.getDivisions()
        if (divisions) {
          const regex = new RegExp(this.searchDivision, 'gi')
          const filteredDivisions =
             divisions
               .filter(el => el.name?.match(regex))
               .filter(division => division.id && !this.selectedDivisions.includes(division.id))
          this.divisions = filteredDivisions ?? []
          this.divisionLoading = false
          this.showDivisions = true
        }
      } else {
        this.showDivisions = false
        this.divisions = []
      }
    },
    mapMediatype (mt: number) {
      const mediaTypeMap: {[key: string]: string} = {
        1: 'all',
        2: 'call',
        3: 'callback',
        4: 'chat',
        5: 'email',
        6: 'message'
      }
      this.mediaType = mediaTypeMap[mt] || 'email'
    },
    AddTag () {
      this.tags = [...this.tags, this.tagName]
      this.tagName = ''
    },
    async toggleModalEmail () {
      this.showReply = true
    },
    closeReplyModal () {
      this.showReply = false;
      (this.$refs.replyChildComponent as { resetData: () => void }).resetData()
    },
    viewInteraction () {
      // const url = `https://apps.mypurecloud.de/directory/#/engage/admin/interactions/${this.conversationId}`
      // window.open(url)
      const conversations = this.profile === 'agent' ? myClientApp.myConversations : myClientApp.conversations
      conversations.showInteractionDetails(this.conversationId)
    },
    openProfile () {
      const url = `https://apps.mypurecloud.de/directory/#/relate-engine/contact/${this.externalContactId}`
      window.open(url)
    },
    transcriptPosition (recording: platformClient.Models.Recording) {
      return this.recordings.length - this.recordings.indexOf(recording)
    },
    formatDateStr (transcript: platformClient.Models.RecordingEmailMessage) {
      return new Date(transcript?.time || '').toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    },
    getTo (transcript: platformClient.Models.RecordingEmailMessage) {
      return transcript.to?.map(el => el?.email)?.join('; ')
    },
    getCC (transcript: platformClient.Models.RecordingEmailMessage) {
      return transcript.cc?.[0]?.email || ''
    },
    getBCC (transcript: platformClient.Models.RecordingEmailMessage) {
      return transcript.bcc?.[0]?.email || ''
    },
    getHtmlBody (transcript: platformClient.Models.RecordingEmailMessage) {
      if (transcript.attachments?.length !== 0) {
        if (transcript.attachments) {
          transcript.attachments.forEach(el => {
            if (el.attachmentId && el.contentPath) {
              transcript.htmlBody = transcript.htmlBody?.replace('cid:' + el.attachmentId?.replace('@', '&#64;'), el.contentPath)
            }
          })
        }
      }
      return transcript ? transcript.htmlBody || transcript.textBody?.replace(/\n/g, '<br>') : ''
    },
    selectMT (mt: number) {
      this.mtSelected = mt
      this.mapMediatype(this.mtSelected)
      this.getInteractions()
    },
    selectTab (tab: number) {
      if (tab !== this.tabSelected) {
        this.tabSelected = tab
        this.showFilters = !this.showFilters
      }
    },
    setFrom (from: string) {
      if (!this.wrongFromEmail && this.from !== '') {
        this.tagName = `${this.tagNameObj[3]} : ${from}`
        this.selectedFroms.push(from)
        this.AddTag()
        this.getInteractions()
        this.from = ''
      }
    },
    setTo (to: string) {
      if (!this.wrongToEmail && this.to !== '') {
        this.tagName = `${this.tagNameObj[4]} : ${to}`
        this.selectedTos.push(to)
        this.AddTag()
        this.getInteractions()
        this.to = ''
      }
    },
    setAni (ani: string) {
      this.tagName = `${this.tagNameObj[5]} : ${ani}`
      this.AddTag()
      this.ani = ''
    },
    setDnis (dnis: string) {
      this.tagName = `${this.tagNameObj[6]} : ${dnis}`
      this.AddTag()
      this.dnis = ''
    },
    setQueue (queue : platformClient.Models.Queue) {
      if (queue.name && queue.id) {
        this.searchQueue = queue.name
        this.tagName = `${this.tagNameObj[0]} : ${queue.name}`
        this.AddTag()
        this.searchQueue = ''
        this.globalQueues.push({ name: queue.name, id: queue.id })
        this.selectedQueues.push(queue.id)
        this.getInteractions()
      }
      this.showQueues = false
    },
    setUser (user : platformClient.Models.User) {
      if (user.name && user.id) {
        this.searchUser = user.name
        this.tagName = `${this.tagNameObj[1]} : ${user.name}`
        this.AddTag()
        this.globalUsers.push({ name: user.name, id: user.id })
        this.selectedUsers.push(user.id)
        this.getInteractions()
        this.searchUser = ''
      }
      this.showUsers = false
    },
    setDivision (division : platformClient.Models.AuthzDivision) {
      if (division.name && division.id) {
        this.searchDivision = division.name
        this.tagName = `${this.tagNameObj[2]} : ${division.name}`
        this.globalDivisions.push({ name: division.name, id: division.id })
        this.selectedDivisions.push(division.id)
        // this.tags = this.tags.filter(el => {
        //   el.includes(this.tagNameObj[2])
        // })
        this.AddTag()
        this.getInteractions()
        this.searchDivision = ''
      }
      this.showDivisions = false
    },
    formatCustomDate (timestamp: number) {
      const date = new Date(timestamp)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const dateString = `${day}/${month}/${year}`
      return dateString
    },
    removeDateTagsWithPrefix () {
      const prefix = this.tagNameObj[7]
      for (let i = this.tags.length - 1; i >= 0; i--) {
        if (this.tags[i].startsWith(prefix, 0)) {
          this.tags.splice(i, 1)
        }
      }
    },
    setCustomDate () {
      this.removeDateTagsWithPrefix()
      this.startValue = Date.parse(Object.values(this.range)[0])
      this.endValue = Date.parse(Object.values(this.range)[1])
      this.showFilter = false
      this.tagName = `${this.tagNameObj[7]} : ${this.formatCustomDate(this.startValue)} - ${this.formatCustomDate(this.endValue)}`
      this.AddTag()
      this.getInteractions()
      this.showFilters = true
      this.tabSelected = 1
      this.range = []
    },
    setDate (index: number) {
      this.removeDateTagsWithPrefix()
      const dateRanges = [
        { start: Date.parse(new Date(new Date().setHours(0, 0, 0, 0)).toISOString()), end: Date.parse(new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 1)).toISOString()) }, // Today
        { start: Date.parse(new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 1)).toISOString()), end: Date.parse(new Date(new Date().setHours(0, 0, 0, 0)).toISOString()) }, // Yesterday
        { start: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay())).toISOString()), end: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() + 7)).toISOString()) }, // This week
        { start: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 7)).toISOString()), end: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay())).toISOString()) }, // Last week
        { start: Date.parse(new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 7)).toISOString()), end: Date.parse(new Date(new Date().setHours(0, 0, 0, 0)).toISOString()) }, // Previous 7 days
        { start: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString()), end: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)).toISOString()) }, // This month
        { start: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)).toISOString()), end: Date.parse(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toISOString()) }, // Last month
        { start: Date.parse(new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 30)).toISOString()), end: Date.parse(new Date(new Date().setHours(0, 0, 0, 0)).toISOString()) } // Previous 30 days
      ]

      if (index >= 0 && index < dateRanges.length) {
        this.startValue = dateRanges[index].start
        this.endValue = dateRanges[index].end
      } else {
        console.error(`Index ${index} is out of range`)
      }
      this.showFilter = false
      this.showFilters = true
      this.tabSelected = 1
      this.tagName = `${this.tagNameObj[7]} : ${this.presets[index]}`
      this.AddTag()
      this.getInteractions()
    },
    onClose (name: string) {
      const getName = () => name.substring(name.indexOf(': ') + 2, name.length)
      const removeFromArray = (arr: string[], id: string) => {
        return arr.filter(el => el !== id)
      }

      switch (true) {
        case this.globalQueues.some(q => q.name === getName()): {
          const queuePos = this.globalQueues.findIndex(q => q.name === getName())
          if (queuePos !== -1) {
            const queueId = this.globalQueues[queuePos].id
            this.selectedQueues = removeFromArray(this.selectedQueues, queueId)
          }
          break
        }
        case this.globalUsers.some(u => u.name === getName()): {
          const userPos = this.globalUsers.findIndex(u => u.name === getName())
          if (userPos !== -1) {
            const userId = this.globalUsers[userPos].id
            this.selectedUsers = removeFromArray(this.selectedUsers, userId)
          }
          break
        }
        case this.globalDivisions.some(d => d.name === getName()): {
          const divisionPos = this.globalDivisions.findIndex(d => d.name === getName())
          if (divisionPos !== -1) {
            const divisionId = this.globalDivisions[divisionPos].id
            this.selectedDivisions = removeFromArray(this.selectedDivisions, divisionId)
          }
          break
        }
        case this.selectedFroms.includes(getName()): {
          const fromPos = this.selectedFroms.findIndex(f => f === getName())
          if (fromPos !== -1) {
            this.selectedFroms.splice(fromPos, 1)
            console.log(this.selectedFroms)
          }
          break
        }
        case this.selectedTos.includes(getName()): {
          const toPos = this.selectedTos.findIndex(t => t === getName())
          if (toPos !== -1) {
            this.selectedTos.splice(toPos, 1)
            console.log(this.selectedTos)
          }
          break
        }

        default:
          console.log('else case')
          break
      }

      this.tags = this.tags.filter(el => el !== name)

      const searchQueueIndex = name.search(this.searchQueue)
      const searchUserIndex = name.search(this.searchUser)
      const searchDivisionIndex = name.search(this.searchDivision)

      if ((searchQueueIndex !== -1 && searchQueueIndex !== 0) ||
          (searchUserIndex !== -1 && searchUserIndex !== 0) ||
          (searchDivisionIndex !== -1 && searchDivisionIndex !== 0)) {
        this.searchQueue = ''
        this.searchUser = ''
        this.searchDivision = ''
      } else if (name.startsWith(this.tagNameObj[7])) {
        this.startValue = 0
        this.endValue = 0
      }
      this.getInteractions()
    },
    handleSortByChange (value: number) {
      const newSortBy = value === 0 ? 'desc' : 'asc'
      if (newSortBy !== this.sortBy) {
        this.sortBy = newSortBy
        this.getInteractions()
      }
    },
    handleScroll () {
      if (this.listElm.scrollTop + this.listElm.clientHeight >= this.listElm.scrollHeight) {
        if (this.pageNumber <= this.pageCount) {
          console.log(this.pageNumber, this.pageCount)
          this.pageNumber++
          this.loadMoreInteractions()
        }
      }
    },
    hidedropMenu (e: Event) {
      const menuEl = document.querySelector('#menu') as HTMLElement
      const btn = document.querySelector('#btnId') as HTMLElement
      const btnMt = document.querySelector('#btnMt') as HTMLElement
      const target = e.target as HTMLElement
      if (btnMt.contains(target)) return
      if (!menuEl || !btn) return
      if (!menuEl.contains(target) && !btn.contains(target)) {
        if (menuEl.className === 'filters dropActive') {
          menuEl.className = 'filters dropInactive'
          this.showFilter = false
          this.range = []
        }
      }
    },
    async intitialFetch (users: string[]) {
      this.searchLoading = true
      // Get value from localStorage
      const storedValue = localStorage.getItem('mediaType') || null
      if (storedValue) {
        this.mtSelected = JSON.parse(storedValue)
      } else {
        this.mtSelected = 5
      }

      // Get the mediatype string to be used in the query
      this.mapMediatype(this.mtSelected)

      // Make the search request
      await genesysCloudService.search(this.pageNumber, this.mediaType, this.startValue = 0, this.endValue = 0, 'unknown', this.searchStr, [], users, [], [], [], this.sortBy)
        .then(interactions => {
          if (interactions) {
            this.interactions = this.removeDuplicate(interactions)
            this.interactionsClone = [...this.interactions]
            this.pageCount = interactions.pageCount
          }
        })

      this.searchLoading = false
    }
  },
  watch: {
    mediaType () {
      localStorage.setItem('mediaType', JSON.stringify(this.mtSelected))
    },
    // isSupervisor (newVal, oldVal) {
    //   console.log('isSupervisor watch: ', newVal, oldVal)
    //   if (newVal !== oldVal) {
    //     console.log('Supervisor profile')
    //     this.intitialFetch([])
    //   } else {
    //     console.log('Agent profile')
    //     this.intitialFetch([this.userId])
    //   }
    // },
    profile (newVal) {
      this.selectedUsers = newVal === 'agent' ? [this.userId] : []
      // const users = newVal === 'agent' ? [this.userId] : []
      this.intitialFetch(this.selectedUsers)
      // if (newVal === 'supervisor') {
      //   this.intitialFetch([])
      // } else if (newVal === 'agent') {
      //   this.intitialFetch([this.userId])
      // }
    }
    // userId (newVal) {
    //   console.log('userId watch: ', newVal)
    //   this.intitialFetch([newVal])
    // }
    // permissions (newPermissions: string[]) {
    //   const isSupervisor = newPermissions.some((permission) => permission.includes('ui:supervisor'))
    //   const userFilter = isSupervisor ? [] : [this.userId]
    //   console.log(userFilter)
    //   this.intitialFetch(userFilter)
    // }
  },
  mounted () {
    this.searchInput = document.querySelector('.search__input') as HTMLElement
    // console.log(this.searchInput)
    this.listElm = document.querySelector('#infinite-list') as HTMLElement
    this.listElm.addEventListener('scroll', this.handleScroll)

    document.addEventListener('click', this.hidedropMenu)
  },
  unmounted () {
    document.removeEventListener('click', this.hidedropMenu)
    this.listElm.removeEventListener('click', this.handleScroll)
  },
  async created () {
    const userFilter = this.isSupervisor ? [] : [this.userId]
    if (this.userId === '') {
      // tis.intitialFetch(userFilter)
    } else {
      this.intitialFetch(userFilter)
    }
  },
  /*
  async created () {
    console.log('Home Created', this.userId, this.checkCurrentRole)
    // Get value from localStorage
    const storedValue = localStorage.getItem('mediaType') || null
    if (storedValue) {
      this.mtSelected = JSON.parse(storedValue)
    } else {
      this.mtSelected = 5
    }

    // Get the mediatype string to be used in the query
    this.mapMediatype(this.mtSelected)

    // Make the search request
    await genesysCloudService.search(this.pageNumber, this.mediaType, this.startValue = 0, this.endValue = 0, 'unknown', this.searchStr, [], this.checkCurrentRole, [], [], [], this.sortBy)
      .then(interactions => {
        if (interactions) {
          this.interactions = interactions.results.reduce((finalArray: platformClient.Models.ArrayNode[], current: platformClient.Models.ArrayNode) => {
            const obj = finalArray.find((item) => item.conversationId === current.conversationId)
            if (obj) {
              return finalArray
            }
            return finalArray.concat([current])
          }, [])
          this.interactionsClone = [...this.interactions]
          this.pageCount = interactions.pageCount
        }
      })
  }, */
  computed: {
    getId () {
      return this.userId
    },
    hideUserFilter () {
      return this.isSupervisor
    },
    queueResult () : boolean {
      return this.queues.length === 0
    },
    userResult () : boolean {
      return this.users.length === 0
    },
    divisionResult () : boolean {
      return this.divisions.length === 0
    },
    dateSelected () {
      return this.range && Object.values(this.range).length > 0
    },
    findStr () {
      return this.interactions.map(interaction => {
        const regex = new RegExp(this.searchStr, 'gi')
        const strHighlighted = interaction.subject.replace(regex, '<span style="background-color: yellow;">$&</span>')
        return { ...interaction, strHighlighted }
      })
    }
  }
})
