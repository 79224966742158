
import genesyscloudService from '@/services/genesyscloud-service'
import Spinner from '@/components/Spinner.vue'
import { defineComponent } from 'vue'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default defineComponent({
  name: 'DisconnectModal',
  components: { Spinner },
  props: ['showModal', 'closeDisconnectModal', 'wrapupCodes', 'conversationId', 'participantId', 'queue', 'getConversationDetails', 'selectedQueueWaiting', 'multipleSelection', 'itemsSelected', 'hideReload'],
  data () {
    return {
      isItemLoading: false,
      searchTerm: '',
      selectedWrapup: { name: '', id: '' }
    }
  },
  inject: ['notyf'],
  methods: {
    resetData () {
      this.selectedWrapup = { name: '', id: '' }
      this.searchTerm = ''
    },
    async disconnectEmail () {
      const notyf = this.notyf as Notyf
      this.isItemLoading = true
      try {
        let requests
        if (!this.multipleSelection) {
          requests = [genesyscloudService.patchConversationEmailParticipant(this.conversationId, this.participantId, this.selectedWrapup.id)]
        } else {
          requests = this.itemsSelected.map((el: { conversationId: string, participantId: string}) => genesyscloudService.patchConversationEmailParticipant(el.conversationId, el.participantId, this.selectedWrapup.id))
        }

        await Promise.all(requests)
        this.closeDisconnectModal()
        notyf.success('Déconnection réussie')
      } catch (error) {
        notyf.error('Une erreur s\'est produite')
        console.log(error)
      } finally {
        setTimeout(() => {
          this.getConversationDetails(this.queue)
          this.isItemLoading = false
          this.hideReload()
        }, 5000)
      }
    }
  },
  computed: {
    filteredWrapupCodes () {
      return this.wrapupCodes.filter((wrapup: { name: string, id: string }) => wrapup.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    },
    selectedItemsNumber () {
      return this.itemsSelected.length
    },
    hasQueueWC () {
      return this.wrapupCodes.length === 0
    }
  }
})
