import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VCalendar from 'v-calendar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMobile, faAddressCard, faMessage, faCommentSms, faUserPlus, faArrowRight, faTriangleExclamation, faCircleCheck, faCircleXmark, faAngleDoubleLeft, faAngleDoubleRight, faHome, faSignal5, faGear, faCircleInfo, faEye, faBan, faHand, faRotate, faRightLeft, faLock, faFilter, faReply } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

const notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'right',
    y: 'top'
  },
  dismissible: true
})

library.add(faMobile, faAddressCard, faMessage, faCommentSms, faUserPlus, faArrowRight, faTriangleExclamation, faCircleCheck, faCircleXmark, faAngleDoubleLeft, faAngleDoubleRight, faHome, faSignal5, faGear, faCircleInfo, faEye, faBan, faHand, faRotate, faRightLeft, faLock, faFilter, faReply)
createApp(App)
  .use(router)
  .component('fa', FontAwesomeIcon)
  .component('calendar', VCalendar)
  .component('EasyDataTable', Vue3EasyDataTable)
  .provide('notyf', notyf)
  .mount('#app')
