import platformClient from 'purecloud-platform-client-v2'
import config from '@/config/config'

const routingApi = new platformClient.RoutingApi()
const externalcontactApi = new platformClient.ExternalContactsApi()
const userApi = new platformClient.UsersApi()
const integrationApi = new platformClient.IntegrationsApi()
const ResponseManagementApi = new platformClient.ResponseManagementApi()
const authorizationApi = new platformClient.AuthorizationApi()
const speechTextAnalyticsApi = new platformClient.SpeechTextAnalyticsApi()
const recordingApi = new platformClient.RecordingApi()
const conversationApi = new platformClient.ConversationsApi()
const analyticsApi = new platformClient.AnalyticsApi()
const notificationsApi = new platformClient.NotificationsApi()

let queueObservationWS : WebSocket

export default {
  // Login to Genesys Cloud
  async loginImplicitGrant (): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search)
    const environment = urlParams.get('environment') || localStorage.getItem('gc-environment') || 'mypurecloud.de'
    const client = platformClient.ApiClient.instance
    client.setPersistSettings(true, 'Email-app')
    client.setEnvironment(environment)
    localStorage.setItem('gc-environment', environment)
    await client.loginImplicitGrant(config.clientId, config.redirectUri)
    console.log('Authenticated')
  },

  async getUsersMe (): Promise<platformClient.Models.User> {
    const data = await userApi.getUsersMe()
    return data
  },

  async getUserById (userId: string): Promise<platformClient.Models.User> {
    const data = await userApi.getUser(userId)
    return data
  },

  async getUserRoles (userId: string): Promise<platformClient.Models.UserAuthorization> {
    const data = await userApi.getUserRoles(userId)
    return data
  },

  async searchUsers (val: string): Promise<platformClient.Models.User[]> {
    const body = {
      pageNumber: 1,
      pageSize: 100,
      query: [
        {
          fields: ['name'],
          type: 'CONTAINS',
          value: ''
        },
        {
          fields: ['state'],
          operator: 'OR',
          type: 'EXACT',
          values: ['active']
        }
      ]
    }
    body.query[0].value = val
    const data = await userApi.postUsersSearch(body)
    return data.results
  },

  async getAllUsers (): Promise<platformClient.Models.User[]> {
    const body = {
      pageNumber: 1,
      pageSize: 100,
      sortOrder: 'asc'
    } as platformClient.Models.UserSearchRequest
    const data = await userApi.postUsersSearch(body)
    return data.results
  },

  async search (pageNumber: number, mediaType: string, startValue: number, endValue: number, language: string, keyword: string, queueIds: string[], userIds: string[], divisionIds: string[], froms: string[], tos: string[], sortOrder: string) : Promise<platformClient.Models.JsonSearchResponse> {
    const body = {
      pageSize: 50,
      pageNumber,
      sort: [{ sortBy: 'startTime', sortOrder }],
      returnFields: [
        'mediaType',
        'conversationId',
        'from',
        'to',
        'subject',
        'startTime',
        'conversationStartTime',
        'participants.queueId',
        'participants.initialDirection'
      ],
      query: [
        {
          type: 'DATE_RANGE',
          startValue,
          endValue,
          fields: ['startTime']
        },
        {
          value: language,
          type: 'EXACT',
          fields: ['language']
        },
        {
          value: keyword,
          type: 'PHRASE',
          operator: 'AND',
          fields: ['transcript.content']
        },
        {
          value: mediaType,
          type: 'EXACT',
          fields: ['mediaType']
        },
        {
          values: queueIds,
          type: 'EXACT',
          fields: ['participants.queueId']
        },
        {
          values: userIds,
          type: 'EXACT',
          fields: ['participants.userId']
        },
        {
          values: divisionIds,
          type: 'EXACT',
          fields: ['participants.divisionId']
        },
        {
          values: froms,
          type: 'EXACT',
          fields: ['from']
        },
        {
          values: tos,
          type: 'EXACT',
          fields: ['to']
        }
      ],
      types: ['transcripts']
    } as platformClient.Models.TranscriptSearchRequest

    if (body && body.query) {
      if (mediaType === 'all') {
        body.query.splice(3, 1)
      }
      if (keyword.length === 0) {
        body.query.splice(1, 2)
      }
      if (queueIds.length === 0) {
        body.query = body.query.filter(({ fields }) => {
          if (fields) {
            return fields[0] !== 'participants.queueId'
          }
        })
      }
      if (userIds.length === 0) {
        body.query = body.query.filter(({ fields }) => {
          if (fields) {
            return fields[0] !== 'participants.userId'
          }
        })
      }
      if (divisionIds.length === 0) {
        body.query = body.query.filter(({ fields }) => {
          if (fields) {
            return fields[0] !== 'participants.divisionId'
          }
        })
      }
      if (froms.length === 0) {
        body.query = body.query.filter(({ fields }) => {
          if (fields) {
            return fields[0] !== 'from'
          }
        })
      }
      if (tos.length === 0) {
        body.query = body.query.filter(({ fields }) => {
          if (fields) {
            return fields[0] !== 'to'
          }
        })
      }
      if (startValue === 0 || endValue === 0) {
        body.query = body.query.filter(({ fields }) => {
          if (fields) {
            return fields[0] !== 'startTime'
          }
        })
      }
    }
    const data = await speechTextAnalyticsApi.postSpeechandtextanalyticsTranscriptsSearch(body)
    return data
  },

  async myInteractions (userId: string): Promise<undefined | platformClient.Models.AnalyticsConversationQueryResponse> {
    const startValue = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 30)).toISOString()
    const endValue = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 1)).toISOString()
    const interval = `${startValue}/${endValue}`

    const body = {
      interval,
      orderBy: 'conversationStart',
      order: 'desc',
      paging: {
        pageSize: 100,
        pageNumber: 1
      },
      segmentFilters: [
        {
          type: 'or',
          predicates:
          [
            {
              dimension: 'mediaType',
              value: 'email'
            }
          ]
        },
        {
          type: 'or',
          predicates:
          [
            {
              dimension: 'direction',
              value: 'inbound'
            },
            {
              dimension: 'direction',
              value: 'outbound'
            }
          ]
        },
        {
          type: 'or',
          predicates:
          [
            {
              dimension: 'userId',
              value: userId
            }
          ]
        },
        {
          type: 'or',
          predicates:
          [
            {
              dimension: 'wrapUpCode',
              value: '86e51b48-4378-4a26-bade-eab24408b8db'
              // value: '69a0f4bd-ef65-4bfc-88b8-2c53c0441e32'
            }
          ]
        }
      ]
    } as platformClient.Models.ConversationQuery

    const data = await analyticsApi.postAnalyticsConversationsDetailsQuery(body)
    return data
  },

  async getInteraction (conversationId: string): Promise<platformClient.Models.Recording[]> {
    const data = await recordingApi.getConversationRecordings(conversationId)
    return data
  },

  async getExternalContactById (contactId: string): Promise<platformClient.Models.ExternalContact> {
    const data = await externalcontactApi.getExternalcontactsContact(contactId)
    return data
  },

  async createEmailConversation (
    queueId: string,
    to: string,
    subject: string,
    htmlBody: string,
    externalContactId: string
  ): Promise<platformClient.Models.EmailConversation> {
    const body = {
      queueId,
      provider: 'PureCloud Email',
      toAddress: to,
      subject: 'Re : ' + subject,
      direction: 'OUTBOUND',
      htmlBody,
      externalContactId
    } as platformClient.Models.CreateEmailRequest
    const data = await conversationApi.postConversationsEmails(body)
    return data
  },

  async getBody (converastionId: string, messageId: string) : Promise<platformClient.Models.EmailMessage> {
    const data = await conversationApi.getConversationsEmailMessage(converastionId, messageId)
    return data
  },

  async getEmailMessages (conversationId: string): Promise<undefined | platformClient.Models.EmailMessage[]> {
    const data = await conversationApi.getConversationsEmailMessages(conversationId)
    return data.entities
  },

  // Get the organization's queues.
  // NOTE: For this sample only get the first 100.
  async getQueues (val: string): Promise<undefined | platformClient.Models.Queue[]> {
    const opts = {
      pageSize: 100,
      pageNumber: 1,
      name: ''
    }
    opts.name = val
    const data = await routingApi.getRoutingQueues(opts)
    return data.entities
  },

  async getQueue (id: string) : Promise<platformClient.Models.Queue> {
    const queueId = id
    const data = routingApi.getRoutingQueue(queueId)
    return data
  },

  async getUserQueues (id: string) : Promise<undefined | platformClient.Models.UserQueue[]> {
    const userId = id
    const opts = {
      pageSize: 100,
      pageNumber: 1
    }

    const data = await userApi.getUserQueues(userId, opts)
    return data.entities
  },

  async postQueueObservation (predicates: platformClient.Models.QueueObservationQueryPredicate) : Promise<undefined | platformClient.Models.QueueObservationDataContainer[]> {
    const body = {
      filter: {
        type: 'and',
        clauses: [
          {
            type: 'or',
            predicates
          },
          {
            type: 'and',
            predicates: [
              {
                dimension: 'mediaType',
                value: 'email'
              }
            ]
          }
        ]
      },
      metrics: [
        'oWaiting',
        'oInteracting'
      ],
      groupBy: [
        'queueId'
      ]
      // interval: '2023-01-27T15:30:00.000Z/2023-01-27T16:00:00.000Z'
    } as platformClient.Models.QueueObservationQuery
    const data = await analyticsApi.postAnalyticsQueuesObservationsQuery(body)
    return data.results
  },

  async postAnalyticsConversationsdetails (queueId: string): Promise<undefined | platformClient.Models.AnalyticsConversationQueryResponse> {
    // Construct today's date
    const startValue = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 30)).toISOString()
    const endValue = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 1)).toISOString()
    const interval = `${startValue}/${endValue}`
    const body = {
      interval,
      orderBy: 'conversationStart',
      order: 'asc',
      paging: {
        pageSize: 100,
        pageNumber: 1
      },
      segmentFilters: [
        {
          type: 'and',
          predicates: [
            {
              type: 'dimension',
              dimension: 'queueId',
              operator: 'matches',
              value: queueId
            },
            {
              type: 'dimension',
              dimension: 'mediaType',
              operator: 'matches',
              value: 'email'
            }
          ]
        }
      ],
      conversationFilters: [
        {
          type: 'and',
          predicates: [
            {
              type: 'dimension',
              dimension: 'conversationEnd',
              operator: 'notExists'
            }
          ]
        }
      ]
    } as platformClient.Models.ConversationQuery
    const data = await analyticsApi.postAnalyticsConversationsDetailsQuery(body)
    return data
  },

  async patchConversationEmailParticipant (conversationId: string, participantId: string, wrapupId: string): Promise<void> {
    const body = {
      wrapup: {
        code: wrapupId,
        notes: 'Wrapup code inserted by Finder'
      },
      state: 'disconnected'
    } as platformClient.Models.MediaParticipantRequest

    const data = await conversationApi.patchConversationsEmailParticipant(conversationId, participantId, body)
    return data
  },

  async replaceParticipant (conversationId: string, participantId: string, selectedObjectType: string, Id: string): Promise<void> {
    const body = {
      userId: '',
      queueId: ''
    } as platformClient.Models.TransferRequest

    switch (selectedObjectType) {
      case 'user':
        body.userId = Id
        break
      case 'queue':
        body.queueId = Id
        break
      default:
        throw new Error(`Invalid selected object: ${selectedObjectType}`)
    }

    const data = await conversationApi.postConversationsEmailParticipantReplace(conversationId, participantId, body)
    return data
  },

  async getQueueWrapupCodes (queueId: string) : Promise<undefined | platformClient.Models.WrapupCode[]> {
    const opts = {
      pageSize: 100,
      pageNumber: 1
    } as platformClient.RoutingApi.getRoutingQueueWrapupcodesOptions
    const data = await routingApi.getRoutingQueueWrapupcodes(queueId, opts)
    return data.entities
  },

  async getSkill (skillId: string) : Promise<platformClient.Models.RoutingSkill> {
    const data = await routingApi.getRoutingSkill(skillId)
    return data
  },

  async getQueuesMe () : Promise<undefined | platformClient.Models.UserQueue[]> {
    const opts = {
      pageSize: 100,
      pageNumber: 1,
      sortOrder: 'asc'
    }
    const data = await routingApi.getRoutingQueuesMe(opts)
    return data.entities
  },

  async getDivisions (): Promise<undefined | platformClient.Models.AuthzDivision[]> {
    const opts = {
      pageSize: 100,
      pageNumber: 1
    }
    const data = await authorizationApi.getAuthorizationDivisions(opts)
    return data.entities
  },

  async getExternalContacts (val: string): Promise<undefined | platformClient.Models.ExternalContact[]> {
    const opts = {
      pageSize: 100,
      pageNumber: 1,
      q: ''
    }
    opts.q = val
    const data = await externalcontactApi.getExternalcontactsContacts(opts)
    return data.entities
  },

  async getResponseLibrary (libraryId: string): Promise<undefined | platformClient.Models.Response[]> {
    const data = await ResponseManagementApi.getResponsemanagementResponses(libraryId)
    return data.entities
  },

  async executeAction (actionId: string, body: Record<string, unknown>): Promise<string | unknown> {
    const data = await integrationApi.postIntegrationsActionExecute(actionId, body)
    return data
  },

  async subscribeToQueuesObservations (queueIds: string[], callbacks: ((message: MessageEvent) => void)[]): Promise<void> {
    let channelId = ''
    const channel = await notificationsApi.postNotificationsChannels()
    if (!channel.connectUri || !channel.id) throw new Error('Channel not created')
    console.log('Channel created')
    channelId = channel.id

    // Assign callbacks to ws
    if (queueObservationWS) queueObservationWS.close()
    queueObservationWS = new WebSocket(channel.connectUri)
    queueObservationWS.onmessage = (message) => {
      for (const cb of callbacks) {
        cb(message)
      }
    }

    // Subscribe to topics
    const topics:platformClient.Models.ChannelTopic[] = []
    queueIds.forEach(queueId => {
      topics.push({
        id: `v2.analytics.queues.${queueId}.observations`
      })
    })

    await notificationsApi.putNotificationsChannelSubscriptions(channelId, topics)
    console.log('Subscribed to topics')
  }
}
