
import genesysCloudService from '@/services/genesyscloud-service'
import platformClient from 'purecloud-platform-client-v2'
import { defineComponent } from 'vue'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default defineComponent({
  name: 'ReplyModal',
  props: ['showModal', 'closeReplyModal', 'queueId', 'externalContactId', 'queueName', 'html', 'subject', 'to'],
  data () {
    return {
      isItemLoading: false,
      queuesLoading: false,
      showQueues: false,
      isQueueRemoved: false,
      searchQueue: '',
      selectedQueuename: '',
      timeout: 0,
      queues: [] as platformClient.Models.UserQueue[],
      internalQueueId: this.queueId
    }
  },
  inject: ['notyf'],
  watch: {
    showModal (newVal) {
      if (newVal === true) {
        this.internalQueueId = this.queueId
        this.selectedQueuename = this.queueName
      }
    }
  },
  methods: {
    debounce () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.getQueues()
      }, 1000)
    },
    async getQueues () {
      if (this.searchQueue.length > 0) {
        this.queuesLoading = true
        await genesysCloudService.getQueuesMe()
          .then(queues => {
            console.log(queues)
            if (queues) {
              const filteredQueues = queues.filter(queue => {
                if (queue.id) {
                  return queue.id !== this.internalQueueId
                }
              })
              const results = filteredQueues.filter(queue => {
                if (queue.name) {
                  return queue.name.toLowerCase().includes(this.searchQueue.toLowerCase())
                }
              })
              this.queues = results
              this.queuesLoading = false
              this.showQueues = true
            }
          })
      } else {
        this.showQueues = false
        this.queues = []
      }
    },
    resetData () {
      //   this.selectedQueuename = ''
      this.isQueueRemoved = false
      this.showQueues = false
      this.searchQueue = ''
      this.internalQueueId = ''
    },
    setQueue (queue : platformClient.Models.UserQueue) {
      if (queue.name && queue.id) {
        this.searchQueue = queue.name
        this.selectedQueuename = queue.name
        this.internalQueueId = queue.id
        console.log(this.selectedQueuename)
        // this.tagName = `${this.tagNameObj[0]} : ${queue.name}`
        // this.AddTag()
        this.searchQueue = ''
        // this.gloablQueues.push({ name: queue.name, id: queue.id })
        // this.selectedQueues.push(queue.id)
        // this.getInteractions()
      }
      this.isQueueRemoved = false
      this.showQueues = false
    },
    removeQueue () {
      this.internalQueueId = ''
      this.isQueueRemoved = true
    },
    async runAction () {
      const notyf = this.notyf as Notyf
      try {
        await genesysCloudService.createEmailConversation(this.checkQueueId, this.to, this.subject, this.html, this.externalContactId)
        notyf.success('Votre brouillon d\'email est préparé')
      } catch (error) {
        console.log(error)
        notyf.error('Une erreur s\'est produite')
      } finally {
        this.closeReplyModal()
      }
    }
  },
  computed: {
    queueResult () {
      return this.queues.length === 0
    },
    queueNamecomputed () {
      return this.selectedQueuename !== '' ? this.selectedQueuename : this.queueName
    },
    checkQueueId () {
      return this.internalQueueId !== '' ? this.internalQueueId : this.queueId
    }
  }
})
