
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String
    },
    onClose: {
      type: Function
    }
  },
  data () {
    return {
      dropStatus: false,
      option: '',
      sortBy: ['Plus récent', 'Plus ancien']
    }
  },
  emits: ['sortChanged'],
  methods: {
    toggleDropdown () {
      this.dropStatus = !this.dropStatus
    },
    show (value: string, key: number) {
      this.option = value
      this.dropStatus = false
      this.$emit('sortChanged', key)
    },
    closeDropdown () {
      this.dropStatus = false
    },
    dropdownMenu (e : Event) {
      const el = document.querySelector('.select-menu')
      const ul = document.querySelector('.options')
      const target = e.target as HTMLElement

      if (!el?.contains(target)) {
        if (this.dropStatus) {
          this.closeDropdown()
        }
      } else {
        if (ul?.contains(target)) {
          this.closeDropdown()
        }
      }
    },
    resetDropdown () {
      this.option = this.sortBy[0]
    }
  },
  created () {
    this.option = this.sortBy[0]
  },
  mounted () {
    document.addEventListener('click', this.dropdownMenu)
  },
  unmounted () {
    document.removeEventListener('click', this.dropdownMenu)
  }
})
