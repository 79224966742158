
import { defineComponent } from 'vue'
import genesyscloudService from '@/services/genesyscloud-service'
import { Item, FilterOption } from 'vue3-easy-data-table'
import ClientApp from 'purecloud-client-app-sdk'
import ConfirmationModal from './ConfirmationModal.vue'
import Spinner from './Spinner.vue'

const myClientApp = new ClientApp({
  pcEnvironment: 'mypurecloud.de'
})

type conversation = {
  conversationId? : string,
  conversationStart?: string,
  name?: string,
  externalContactId?: string,
  participantId?: string,
  queueId?: string,
  from?: string,
  to?: string,
  subject?: string,
  direction?: string,
  customFields?: Record<string, unknown>,
  flattenCustomFields: string
}

export default defineComponent({
  name: 'MyInteractionsTable',
  components: {
    ConfirmationModal,
    Spinner
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    conversations: {
      type: Array,
      required: true
    },
    contacts: {
      type: Array,
      required: true
    },
    reload: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      searchValue: '',
      typeCriteria: '',
      conversationId: '',
      htmlBody: '',
      isLoading: false,
      isItemLoading: false,
      showEmailBodyModal: false,
      itemSelected: {} as Item,
      headers: [
        { text: 'DE', value: 'from', sortable: true },
        // { text: 'À', value: 'to', sortable: true },
        { text: 'OBJET', value: 'subject', sortable: true },
        { text: 'DATE', value: 'conversationStart', sortable: true },
        { text: 'ACTION', value: 'action' }
      ],
      items: this.conversations
    }
  },
  watch: {
    conversations () {
      this.items = this.conversations
    }
  },
  methods: {
    viewInteraction (item: Item) {
      const selectedItem = item as conversation
      if (selectedItem && selectedItem.conversationId) {
        this.conversationId = selectedItem.conversationId
        myClientApp.alerting.showToastPopup('Interaction', 'L\'interaction est ouverte')
        myClientApp.conversations.showInteractionDetails(this.conversationId)
      }
    },
    openConfirmation (item: Item) {
      this.itemSelected = item
      this.showEmailBodyModal = true
    },
    closeConfirmation () {
      this.showEmailBodyModal = false
      this.conversationId = ''
      this.itemSelected = {}
    },
    formatDate (val: number | string): string {
      return new Date(val).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    },
    async reply () {
      this.showEmailBodyModal = false
      this.isLoading = true
      try {
        const res = await genesyscloudService.getInteraction(this.itemSelected.conversationId)
        const recordings = res.reverse().map(recording => recording.emailTranscript)

        const htmlArr = []

        for (const recording of recordings) {
          if (recording) {
            const el = recording[0]
            const { from, to, cc, time, subject, htmlBody, textBody } = el
            if (to && time) {
              const ccString = cc && cc.length > 0 ? `CC: ${cc.map(cc => cc.email).join('; ')} <br/>` : ''
              const attachments = el.attachments || []

              htmlArr.push(`
              <br/> <br/> <div style="border-bottom: 1px solid #e6eaee"></div> <br/> <br/>
              De: ${from?.email} <br/>
              À: ${to.map(to => to.email).join('; ')} <br/>
              ${ccString}
              Date: ${this.formatDate(time)} <br/>
              Objet: ${subject} <br/> <br/>
              ${htmlBody || textBody}
            `)

              for (const attachment of attachments) {
                if (attachment.attachmentId && attachment.contentPath) {
                  htmlArr[htmlArr.length - 1] = htmlArr[htmlArr.length - 1].replace('cid:' + attachment.attachmentId.replace('@', '&#64;'), attachment.contentPath)
                }
              }
            }
          }
        }

        this.htmlBody = htmlArr.join('')
        // recordings.map(el => {
        //   if (el && el[0].to && el[0].time) {
        //     this.htmlBody += `
        //       <br/> <br/> <div style="border-bottom: 1px solid #e6eaee"></div> <br/> <br/>
        //       De: ${el[0].from?.email} <br/>
        //       À: ${el[0].to.map(to => to.email).join('; ')} <br/>
        //       ${el[0].cc && el[0].cc.length > 0 ? `CC: ${el[0].cc.map(cc => cc.email).join('; ')} <br/>` : ''}
        //       Date: ${this.formatDate(el[0].time)} <br/>
        //       Objet: ${el[0].subject} <br/> <br/>
        //       ${el[0].htmlBody ? el[0].htmlBody : el[0].textBody}`
        //   }
        // })

        // recordings.forEach(recording => {
        //   if (recording && recording[0].attachments) {
        //     recording[0].attachments.forEach(el => {
        //       if (el.attachmentId && el.contentPath) {
        //         this.htmlBody = this.htmlBody?.replace('cid:' + el.attachmentId?.replace('@', '&#64;'), el.contentPath)
        //       }
        //     })
        //   }
        // })
        await this.createInteraction(this.itemSelected)
      } catch (error) {
        console.log(error)
      }
    },
    async createInteraction (item: Item) {
      genesyscloudService.createEmailConversation(item.queueId, item.from, item.subject, this.htmlBody, item.externalContactId)
        .then(() => {
          myClientApp.alerting.showToastPopup('Email', 'Votre brouillon d\'email est préparé')
          this.isLoading = false
          this.htmlBody = ''
        })
        .then(() => this.updateWrapup())
        .catch(error => console.log(error))

      // try {
      //   const response = await genesyscloudService.createEmailConversation(item.queueId, item.from, item.subject, this.htmlBody, item.externalContactId, item.conversationId, item.participantId)
      //   myClientApp.alerting.showToastPopup('Email', 'Votre brouillon d\'email est préparé')
      //   this.isLoading = false
      //   this.htmlBody = ''
      //   console.log('RES: ', response.status)
      // } catch (error) {
      //   console.log(error)
      // }
    },
    async updateWrapup () {
      genesyscloudService.patchConversationEmailParticipant(this.itemSelected.conversationId, this.itemSelected.participantId, '6a946722-9e08-46fc-9261-acfdd86aa115')
        .then(() => {
          this.itemSelected = {}
          setTimeout(() => {
            this.reload()
          }, 5000)
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    filterOptions () {
      const filterOptionsArray: FilterOption[] = []
      if (this.typeCriteria !== '') {
        filterOptionsArray.push({
          field: 'name',
          comparison: '=',
          criteria: this.typeCriteria
        })
      }
      // this.showTypeFilter = false
      return filterOptionsArray
    }
  }
})
