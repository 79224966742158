
import { defineComponent } from 'vue'
import platformClient from 'purecloud-platform-client-v2'
import genesysCloud from './../services/genesyscloud-service'
import MyInteractionsTable from './../components/MyInteractionsTable.vue'
import Spinner from './../components/Spinner.vue'

type conversation = {
  conversationId? : string,
  conversationStart?: string,
  name?: string,
  externalContactId?: string,
  participantId?: string,
  queueId?: string,
  from?: string,
  subject?: string,
  direction?: string,
  customFields?: Record<string, unknown>,
  flattenCustomFields?: string
}

export default defineComponent({
  name: 'MyInteractions',
  props: ['userId'],
  components: {
    MyInteractionsTable,
    Spinner
  },
  data () {
    return {
      isLoading: false,
      contacts: [] as string[],
      conversations: [] as Array<conversation>,
      externalcontactIds: [] as string[]
    }
  },
  watch: {
    userId () {
      this.getInteractions()
    },
    conversations () {
      this.externalcontactIds = [...new Set(this.filteredConversations.map(el => el.externalContactId || ''))]
      this.getExternalContact()
    }
  },
  methods: {
    async getInteractions () {
      this.isLoading = true
      try {
        const res = await genesysCloud.myInteractions(this.userId)
        if (res && res.conversations) {
          const { conversations } = res
          this.conversations = conversations.map((item) => {
            const participants = item.participants
            if (!participants) {
              return {
                conversationId: item.conversationId
              }
            }
            let participantName = ''
            let participantId = ''
            let externalContactId = ''
            let addressFrom = ''
            let subject = ''
            let addressTo = ''
            let direction = ''
            let queueId = ''
            const externalParticipant = participants.find(p => p.purpose === 'external') || participants.find(p => p.purpose === 'customer')
            if (externalParticipant) {
              participantName = externalParticipant.participantName || ''
              if (participantName.trim() !== '') {
                if (!this.contacts.includes(participantName)) {
                  this.contacts.push(participantName)
                }
              }
              externalContactId = externalParticipant.externalContactId || ''
              if (externalParticipant.sessions && externalParticipant.sessions[0]) {
                const {
                  addressFrom: externalAddressFrom,
                  addressTo: externalAddressTo,
                  direction: externalDirection,
                  segments
                } = externalParticipant.sessions[0]
                addressFrom = externalAddressFrom || ''
                addressTo = externalAddressTo || ''
                direction = externalDirection || ''
                if (segments && segments[0]) {
                  subject = segments[0].subject || ''
                }
              }
            }

            const acdSessionsArr = participants.flatMap(p =>
              p.purpose === 'acd'
                ? p.sessions?.reduce((last, current) => current.metrics || last.metrics ? current : last, {})
                : []
            )

            acdSessionsArr.forEach(session => {
              if (session?.segments && session.segments.length > 0) {
                const lastSegment = session?.segments[session.segments?.length - 1]
                queueId = lastSegment.queueId || ''
              }
            })

            const lastAgent = participants.reduce((last: platformClient.Models.AnalyticsParticipantWithoutAttributes, current: platformClient.Models.AnalyticsParticipantWithoutAttributes) => {
              return current.purpose === 'agent' ? current : last
            }, {})

            if (lastAgent) {
              participantId = lastAgent.participantId || ''
            }
            // const sessionWithSegment = acdSessionsArr.find(session => session?.segments?.some(segment => segment.disconnectType === 'transfer'))
            // if (sessionWithSegment && sessionWithSegment.segments) {
            //   queueId = sessionWithSegment.segments.find(segment => segment.disconnectType === 'transfer')?.queueId ?? ''
            //   console.log('Queue Id retrieved is : ', queueId)
            // }
            // const lastAcd = participants.reduce((last: platformClient.Models.AnalyticsParticipantWithoutAttributes, current: platformClient.Models.AnalyticsParticipantWithoutAttributes) => {
            //   return current.purpose === 'acd' ? current : last
            // }, {})

            // console.log('Last Queue', acdSessionsArr)
            return {
              conversationId: item.conversationId,
              name: participantName,
              participantId,
              externalContactId,
              from: addressFrom,
              to: addressTo,
              queueId,
              subject,
              direction,
              conversationStart: new Date(
                item.conversationStart ? item.conversationStart : ''
              )
                .toLocaleString([], {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    async getExternalContact () {
      function getCustomFileds (id: string, contacts: platformClient.Models.ExternalContact[]) {
        const contact = contacts.find(contact => contact.id === id)
        return contact ? contact.customFields : undefined
      }

      const promises = this.externalcontactIds.map(contactId => genesysCloud.getExternalContactById(contactId))
      const externalContacts = await Promise.all(promises)

      this.filteredConversations.forEach(conversation => {
        const id = this.externalcontactIds.find(externalcontactId => externalcontactId === conversation.externalContactId)
        if (id) {
          conversation.customFields = getCustomFileds(id, externalContacts)
          conversation.flattenCustomFields = JSON.stringify(getCustomFileds(id, externalContacts))
        }
      })
    },
    reload () {
      this.getInteractions()
    }
  },
  computed: {
    filteredConversations (): Array<conversation> {
      return this.conversations.filter(conversation => conversation.direction !== 'outbound')
    }
  }
})
