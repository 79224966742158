
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String
    },
    onClose: {
      type: Function
    }
  },
  computed: {
    displayName () {
      interface TranslationMap {
        [key: string]: string | { [key: string]: string };
        Queue: string;
        User: string;
        Division: string;
        From: string;
        To: string;
        Date: {
          Today: string;
          Yesterday: string;
          'This week': string;
          'Last week': string;
          'Previous 7 days': string;
          'This month': string;
          'Last month': string;
          'Previous 30 days': string;
        }
      }
      const translations: TranslationMap = {
        Queue: 'File d\'attente',
        User: 'Utilisateur',
        Division: 'Division',
        From: 'De',
        To: 'À',
        Date: {
          Today: 'Aujourd\'hui',
          Yesterday: 'Hier',
          'This week': 'Cette semaine',
          'Last week': 'La semaine dernière',
          'Previous 7 days': '7 jours précédents',
          'This month': 'Ce mois-ci',
          'Last month': 'Le mois dernier',
          'Previous 30 days': '30 jours précédents'
        }
      }
      const splitName = this.name?.split(':').map(str => str.trim()) ?? []
      const type = splitName[0]
      const value = splitName[1]

      if (translations[type]) {
        if (typeof translations[type] === 'string') {
          return `${translations[type]}: ${value}`
        } else {
          const typeObj = translations[type]
          if (typeof typeObj === 'string') {
            return `${typeObj}: ${value}`
          } else if (value && typeObj[value]) {
            return `Date: ${typeObj[value]}`
          } else {
            return `Date: ${value}`
          }
        }
      } else {
        return this.name
      }
    }
  }
})
